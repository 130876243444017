import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CrudAction } from '@core/enums/crud-action.enum';
import { ListOptions } from '@capturum/api';
import { FormUtil } from '@core/utils/form-util';
import { AppInjector } from '../../app-injector.service';
import { AppRoutes, FormRoutes } from '@core/enums/routes.enum';
import { HttpRequest } from '@angular/common/http';
import { FormAction } from '@core/enums/form-action.enum';
import { LayoutConfig } from '@shared/services/layout-config.service';
import { FormConfigService } from '@shared/services/form-config.service';
import { FormConfig, FormHelper, FormState } from '@core/enums/form-config.enum';
import { distinctUntilChanged, map, shareReplay, skip, takeUntil, tap } from 'rxjs/operators';
import { DxpBaseEntityComponent } from '@core/base/base-entity.class';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { Farm } from '@core/models/farm.model';
import { MapItem } from '@capturum/ui/api';
import { BlockService } from '@core/api/block.service';
import { ConverterUtil } from '@core/utils/converter-util';
import { UnsavedChangesWarningService } from '@core/services/unsaved-changes-warning.service';
import { UnsavedChanges, UnsavedChangesResponse } from '@core/interfaces/unsaved-changes.interface';
import { IEntityBase } from '@core/interfaces/entity-base';

@Component({
  template: '',
})
export class BaseFormComponent<EntityModel extends IEntityBase>
  extends DxpBaseEntityComponent<EntityModel>
  implements OnInit, UnsavedChanges
{
  public Actions: typeof CrudAction = CrudAction;
  public FormAction: typeof FormAction = FormAction;
  public form: UntypedFormGroup;

  // Soon to be @Deprecated (public):
  public isEdit: boolean;
  public isCopy: boolean;
  public blockOptions$: Observable<MapItem[]>;
  public activeFarmId: string;
  public activeFarm$: Observable<Farm>;

  protected store: Store;
  protected formData: boolean;
  protected formConfig: FormConfig = {
    submitIcon$: new BehaviorSubject<string>('fa fa-check'),
  };

  // Soon to be @Deprecated (public):
  protected apiOptions: ListOptions;
  protected onDataLoad: Subject<EntityModel> = new Subject();
  protected identifier = 'id';
  protected identifierValue: string;
  protected getMethod = 'get';

  // Injectable dependencies:
  protected formConfigService: FormConfigService<EntityModel>;
  protected blockService: BlockService;
  protected unsavedChangesWarningService: UnsavedChangesWarningService;

  constructor(protected route: ActivatedRoute) {
    super(route);

    this.formConfigService = AppInjector.getInjector().get(FormConfigService);
    this.blockService = AppInjector.getInjector().get(BlockService);
    this.unsavedChangesWarningService = AppInjector.getInjector().get(UnsavedChangesWarningService);
    this.store = AppInjector.getInjector().get(Store);
    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);

    this.detectForm();
  }

  public get submitting(): boolean {
    return this.formConfig.state === FormState.isSubmitting;
  }

  public set submitting(value: boolean) {
    if (value) {
      this.formConfig.state = FormState.isSubmitting;
      this.formConfig.submitIcon$.next('fas fa-spinner fa-pulse');
    } else {
      this.formConfig.state = FormState.isIdle;
      this.formConfig.submitIcon$.next('fa fa-check');
    }
  }

  protected get getAction(): CrudAction {
    return this.isEdit ? this.Actions.update : this.Actions.create;
  }

  protected get getEntity(): Observable<string> {
    return this.translateService.stream(`dxp.${this.entityConfig.name}.entity_name`);
  }

  protected get farmField(): UntypedFormControl {
    return this.form?.get('farm_id') as UntypedFormControl;
  }

  protected get blockField(): UntypedFormControl {
    return this.form?.get('block_id') as UntypedFormControl;
  }

  public hasUnsavedChanges(): UnsavedChangesResponse {
    return this.unsavedChangesWarningService.defaultHasUnsavedChanges(this.form, this.submitting);
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:pagehide', ['$event'])
  public refreshUnsavedChangesPopup(event: any): string | boolean {
    return this.unsavedChangesWarningService.handleBrowserRefresh(this.form, event);
  }

  public ngOnInit(): void {
    this.activeFarmId = this.store.selectSnapshot(GeneralSelectors.getActiveFarm)?.id;

    this.detectForm();
    this.createForm();
    this.getConfig();
    this.listenForFarmChange();
  }

  /***
   * This method functions as a hook where the form is created with Angular's FormBuilder. It is always executed
   * before edit/add mode is activated, but after setting the entityConfig and the formConfig.
   */
  protected createForm(): void {
    // Hook
  }

  protected addModeResource(): Observable<any> {
    return of(null);
  }

  protected editModeResource(): Observable<any> {
    return this.apiService.get(this.entityConfig?.id, this.entityConfig?.apiOptions).pipe(
      tap((entity: EntityModel) => {
        this.entity = entity;
      }),
      shareReplay(),
      takeUntil(this.destroy$),
    );
  }

  protected overwriteEntityResource(): Observable<any> {
    if (FormHelper.isAdd(this.formConfig)) {
      return this.addModeResource();
    } else {
      return this.editModeResource();
    }
  }

  protected addModeResourceUpdated(): Observable<any> {
    return of(null);
  }

  protected editModeResourceUpdated(entity: EntityModel): void | Observable<EntityModel> {
    this.form.patchValue(entity);

    return of(entity);
  }

  protected overwriteDefaultConfig(entity: EntityModel, defaultConfig: LayoutConfig): LayoutConfig {
    return {
      ...defaultConfig,
      actionButtons: this.formConfigService.overwriteDefaultFormButtons(
        defaultConfig.actionButtons,
        defaultConfig.backUrl,
        () => {
          return this.submit();
        },
      ),
    };
  }

  protected overwriteResourceUpdated(entity: EntityModel, additionalResources?: any): void | Observable<any> {
    if (FormHelper.isAdd(this.formConfig)) {
      return this.addModeResourceUpdated();
    } else {
      return this.editModeResourceUpdated(entity);
    }
  }

  protected generateDefaultConfig(entity: EntityModel, updatedObservedValue?: any): Observable<LayoutConfig> {
    return this.formConfigService.generateDefaultConfig(entity, this.entityConfig, this.pageConfig, this.formConfig);
  }

  protected submit(data?: any): void {
    if (this.submitting) {
      return;
    }

    let request = data ? data : this.form.value;

    request = this.formData ? this.apiService.toFormData(request) : request;

    this.action(request);
  }

  protected action(data: any): void {
    if (!this.checkFormValidity()) {
      return;
    }

    this.submitting = true;

    (this.apiService[this.getAction](data, this.apiOptions) as Observable<HttpRequest<any>>).subscribe(
      (response) => {
        return this.successfullySubmitted(response);
      },
      (error) => {
        return (this.submitting = false);
      },
    );
  }

  /*** Deprecated in favor of generateDefaultConfig
   *@deprecated
   *
   * @param prePopulate
   */
  protected createConfig(item?: any): LayoutConfig {
    return {
      subtitle: this.translateService.stream(`dxp.${this.entityConfig.name}.title`),
      entity: this.entityConfig.name,
      url: this.router.url,
    };
  }

  protected successfullySubmitted(entity?: any): void {
    const title = this.translateService.instant('toast.success.title');
    const entityName = this.translateService.instant(`dxp.${this.entityConfig.name}.entity_name`);
    const message = this.translateService.instant(`toast.success.${this.getAction}`, { entity: entityName });

    this.notificationService.success(title, message);
    this.goBack();
  }

  protected goBack(): void {
    const backUrl = this.layoutConfigService.getConfig(this.router.url)?.backUrl;

    Array.isArray(backUrl) ? this.router.navigate(backUrl) : this.router.navigateByUrl(backUrl);
  }

  protected detectForm(): void {
    // @Deprecated
    this.isEdit = !!this.route.snapshot.paramMap?.get(this.identifier);
    this.isCopy = !!this.route.snapshot.paramMap?.get('copyId');
    this.identifierValue = this.isCopy
      ? this.route.snapshot.paramMap?.get('copyId')
      : this.route.snapshot.paramMap?.get(this.identifier);

    this.entityConfig = {
      ...this.entityConfig,
      id: this.identifierValue,
    };

    this.formConfig = {
      ...this.formConfig,
      mode: this.detectMode(),
    };
  }

  protected detectMode(): FormRoutes {
    if (this.route.snapshot.paramMap?.get(this.identifier)) {
      return FormRoutes.edit;
    } else if (this.route.snapshot.paramMap?.get('copyId')) {
      return FormRoutes.copy;
    }

    return FormRoutes.add;
  }

  protected checkFormValidity(): boolean {
    if (this.form.invalid) {
      FormUtil.markAsTouched(this.form);
    }

    return this.form.valid;
  }

  protected listenForFarmChange(): void {
    this.activeFarm$
      .pipe(distinctUntilChanged(), skip(this.isEdit && this.farmField?.value ? 1 : 0), takeUntil(this.destroy$))
      .subscribe((activeFarm) => {
        return this.handleFarmUpdate(activeFarm);
      });
  }

  protected handleFarmUpdate(farm: Farm): void {
    if (this.farmField) {
      this.farmField?.setValue(farm?.id);

      if (this.blockField) {
        this.populateBlockList(farm?.id);
      }
    }

    if (this.activeFarmId !== farm?.id) {
      this.submitting = true;

      if (this.entityConfig.displayName) {
        this.router.navigate([`/${AppRoutes.admin}/${this.entityConfig.displayName}`]);
      } else if (this.entityConfig.name) {
        this.router.navigate([`/${AppRoutes.admin}/${this.entityConfig.name}`]);
      }
    }
  }

  protected populateBlockList(farmId?: string): void {
    const options = this.blockListOptions(farmId || this.farmField?.value);

    this.blockOptions$ = this.getBlockList(options);
  }

  protected getBlockList(options?: ListOptions): Observable<MapItem[]> {
    return this.blockService.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  protected blockListOptions(farmId?: string): ListOptions {
    return {
      filters: [
        {
          field: 'farm_id',
          value: farmId || this.farmField?.value,
        },
      ],
    };
  }
}
